import React from "react";
import {
  PostContainer,
  PostContent,
  PostImg,
  PostLink,
  PostTitle,
} from "./PostItem.styles";

const PostItem = ({ item }) => {
  return (
    <PostContainer>
      <PostLink target="_blank" href={item.url}>
        <PostContent>
          <PostImg src={item.image} alt={item.imgalt} />
          <PostTitle>
            <a target="_blank" className="pa" href={item.url} rel="noreferrer">
              <span>{item.title}</span>
            </a>
          </PostTitle>
        </PostContent>
      </PostLink>
    </PostContainer>
  );
};

export default PostItem;
