import React, { useState, useEffect } from "react";
import { MainContainer } from "./Posts.styles";
import { PostsData } from "../../data/data";
import PostItem from "./PostItem";

const Posts = () => {
  const [postList, setPostList] = useState(null);

  useEffect(() => {
    if (!PostsData) return;
    setPostList(PostsData);
  }, []);

  return (
    <MainContainer>
      {postList &&
        postList.map((item) => {
          return <PostItem key={item.title} item={item} />;
        })}
    </MainContainer>
  );
};

export default Posts;
