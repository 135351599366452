import React from "react";
import { BtnContainer, MainContainer } from "./Buttons.styles";

const Buttons = ({ custumClass = "", isText, children }) => {
  return (
    <MainContainer className={custumClass}>
      <BtnContainer className={isText && "textBtn"}>{children}</BtnContainer>
    </MainContainer>
  );
};

export default Buttons;
