import styled from "styled-components";

export const ProjectContainer = styled.div`
  flex-basis: calc(100% / 3 - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 20px;
  height: 540px;

  @media (max-width: 767px) {
    max-width: 324px;
  }

  @media (max-width: 991px) {
    flex-basis: calc(50% - 20px);
  }

  @media (max-width: 576px) {
    flex-basis: calc(100%);
  }
`;

export const ProjectBox = styled.div`
  min-width: 300px;
  position: relative;
  height: 540px;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background-color: #fff;
    transform: skew(2deg, 2deg);
    z-index: -1;
    background: linear-gradient(315deg, #ff0000, #ffc107);
  }

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.05);
    pointer-events: none;
  }

  &:hover h2 {
    top: -140px;
  }

  &:hover a {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
  }
`;

export const ProjectContent = styled.div`
  position: relative;
  padding: 20px;
  transform: translateY(40px);
`;

export const ProjectImgContainer = styled.div`
  position: relative;
  margin-bottom: 20px;

  &::before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background-color: #fff;
    transform: skew(-2deg, -2deg);
    z-index: -1;
    background: linear-gradient(315deg, #646464, #424242);
  }
`;

export const ProjectImg = styled.img`
  width: 100%;
`;

export const ProjectLogo = styled.h2`
  position: absolute;
  top: -60px;
  right: 20px;
  margin: 0;
  padding: 0;
  font-size: 10em;
  color: rgba(255, 255, 255, 0.05);
  transition: 0.5s;
`;

export const ProjectTitle = styled.h3`
  margin: 0 0 10px;
  padding: 0;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
`;

export const ProjectDescription = styled.p`
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 16px;
  font-size: 16px;
  line-height: 1.75;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`;

export const ProjectTech = styled.p`
  font-weight: bolder;
  color: #fff;
  font-size: 16px;
  line-height: 1.75;
  margin: 0 auto;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`;

export const ProjectLink = styled.a`
  position: relative;
  margin: 15px 5px 0 0;
  padding: 7px 25px;
  text-decoration: none;
  border: 2px solid gray;
  display: inline-block;
  transition: 0.5s;
  color: gray;
  transform: translateY(-15px);
  opacity: 0;
  visibility: hidden;

  &:hover {
    color: #fec810;
    border: 2px solid #fec810;
  }
`;
