import styled from "styled-components";

export const MainContainer = styled.nav`
  overflow: hidden;
  background-color: none;
  transition: background-color 0.3s ease-in-out, padding 0.5s linear;
  height: 70px;
  display: flex;
  align-items: center;
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  &.sticky {
    background-color: #212529;
    transition: background-color 0.4s ease-out, padding 0.5s linear;
  }

  @media (max-width: 990px) {
    background-color: #212529;
  }
`;

export const MainContent = styled.div`
  width: 90%;
  max-width: 1140px;
  margin: auto;

  display: flex;
  justify-content: space-between;

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const LeftCol = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Logo = styled.img`
  width: 38px;
  height: 35px;
  cursor: pointer;
`;

export const RightColDesktop = styled.ul`
  text-transform: uppercase;
  flex-direction: row;
  display: flex;
  color: blue;
  text-align: left;

  @media (max-width: 990px) {
    display: none;
  }
`;

export const RightColMobile = styled.ul`
  text-transform: uppercase;
  flex-direction: row;
  display: flex;
  color: #212529;
  text-align: left;

  @media (min-width: 991px) {
    display: none;
  }
`;

export const MobileNavIconContainer = styled.div`
  width: 90px;
  height: 35px;
  background-color: #fed136;
  border: none;
  border-radius: 3px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
  font-weight: bold;
  cursor: pointer;
`;

export const MobileNavIconIconContainer = styled.div`
  width: 20px;
  height: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MobileNavIconIcon = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 3px;
  background-color: black;
`;

export const MobileNavIconText = styled.div`
  margin-right: 5px;
`;

export const DrawerContainer = styled.div`
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  height: 0;
  z-index: 10;
  background-color: #212529;
  visibility: hidden;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &.open {
    height: 200px;
    visibility: visible;
  }

  &.close {
    height: 0;
    visibility: hidden;
  }

  @media (min-width: 991px) {
    display: none;
  }
`;

export const DrawerContent = styled.div`
  width: 90%;
  height: 200px;
  margin: auto;
  padding: 8px 0 15px;
  box-sizing: border-box;
`;

export const NavItemsContainer = styled.div`
  & a {
    text-decoration: None;
    display: list-item;
    list-style: none;
    color: white;
    font-size: 17px;
    letter-spacing: 1px;
    cursor: pointer;

    :hover,
    :active,
    :focus,
    &.active {
      color: #fed136;
    }

    :not(:last-child) {
      margin-right: 1rem;
    }

    &.sticky {
    }
  }

  &.MobileNavItemsContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
`;

export const NavItem = styled.a`
  text-decoration: None;
  display: list-item;
  list-style: none;
  color: white;
  font-size: 17px;
  letter-spacing: 1px;
  cursor: pointer;

  :hover,
  :active,
  :focus {
    color: #fed136;
  }

  :not(:last-child) {
    margin-right: 1rem;
  }

  &.sticky {
  }
`;
