import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @media (max-width: 992px) {
    & div {
      flex-basis: calc(50% - 20px);
    }
  }

  @media (max-width: 575px) {
    & div {
      flex-basis: calc(100% - 20px);
    }
  }
`;
