import React, { useState, useEffect } from "react";
import { MainContainer } from "./Skills.styles";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import SkillToolItem from "./SkillToolItem";
import { SkillsData } from "../../data/data";

const Skills = () => {
  const [skillList, setSkillList] = useState(null);

  useEffect(() => {
    if (!SkillsData) return;
    setSkillList(SkillsData);
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 0,
    cssEase: "ease",
    draggable: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <MainContainer>
      <Slider className="skillsTopRow" {...sliderSettings}>
        {skillList &&
          skillList.top.map((item) => {
            return <SkillToolItem key={item.name} item={item} />;
          })}
      </Slider>
      <Slider {...sliderSettings}>
        {skillList &&
          skillList.bottom.map((item) => {
            return <SkillToolItem key={item.name} item={item} />;
          })}
      </Slider>
    </MainContainer>
  );
};

export default Skills;
