import styled, { keyframes } from "styled-components";
import BtnBG from "../../assets/img/posts/btn-bg.png";

export const PostContainer = styled.div`
  flex-basis: calc(100% / 3 - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PostLink = styled.a`
  text-decoration: none;
`;

const PostShadowAnimate = keyframes`
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
`;

export const PostContent = styled.div`
  padding: 20px;
  position: relative;
  background: linear-gradient(0deg, #000, #262626);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    background: linear-gradient(
      45deg,
      #fb0094,
      #0000ff,
      #00ff00,
      #ffff00,
      #ff0000,
      #fb0094,
      #0000ff,
      #00ff00,
      #ffff00,
      #ff0000
    );
    background-size: 400%;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    z-index: -1;
    animation: ${PostShadowAnimate} 20s linear infinite;
  }

  &:hover a {
    color: black;
  }

  &:hover a::before {
    width: 102%;
    border-radius: 10px;
  }
`;

export const PostImg = styled.img`
  border: 6px solid rgba(255, 255, 255, 0.055);
  box-shadow: 0px 0px 10px 7px gray;
  width: 95%;
  margin-bottom: 20px;
`;

export const PostTitle = styled.div`
  a {
    text-decoration: none;
    position: relative;
    transition: 0.5s;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
  }

  a:hover {
    color: black;
  }

  a::before {
    content: "";
    position: absolute;
    top: 0;
    left: -10;
    width: 0%;
    height: 100%;
    background-image: url(${BtnBG});
    background-size: cover;
    transform-origin: left;
    transition: 0.5s ease;
  }

  a:hover::before {
    width: 102%;
    border-radius: 10px;
  }

  a span {
    position: relative;
    z-index: 1;
  }
`;
