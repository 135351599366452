import styled from "styled-components";

export const MainContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 12px;
  height: 100%;
  background: linear-gradient(to top, #fcb045, #fd1d1d, #833abf);
`;
