import styled from "styled-components";

export const MainContainer = styled.div`
  &.showMoreLessBtn {
    margin-bottom: 10px;
  }

  &.showMoreLessBtn div {
    width: 160px;
    height: 55px;
  }
`;

export const BtnContainer = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  color: gray;
  background-color: black;
  border: 1.5px solid gray;
  font-size: 20px;
  box-sizing: border-box;
  transition: all 0.15s;
  cursor: pointer;

  &.textBtn {
    width: 285px;
    height: 55px;
  }

  &:hover {
    color: #fed136;
    border-color: #fed136;
  }

  &:active {
    color: white;
    border-color: white;
  }
`;
