import React from "react";
import { MainContainer } from "./Contact.styles";
import ContactMethod from "./ContactMethod";
import ContactForm from "../forms/ContactForm";

const Contact = () => {
  return (
    <MainContainer>
      <ContactMethod withDisplay={true} />
      <ContactForm />
    </MainContainer>
  );
};

export default Contact;
