import React from "react";
import { SkillItem, SkillItemContainer, SkillItemTitle } from "./SkillToolItem.styles";

const SkillToolItem = ({item}) => {
  return (
    <SkillItemContainer>
      <SkillItem>
        {item.logo}
      </SkillItem>
      <SkillItemTitle>{item.name}</SkillItemTitle>
    </SkillItemContainer>
  );
};

export default SkillToolItem;
