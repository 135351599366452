import React, { useState, useEffect } from "react";
import {
  MainContainer,
  FormWrapper,
  FieldRow,
  Label,
  Input,
  TextArea,
  Dropdown,
  Option,
  SubmitButton,
  ErrorMessage,
  CaptchaContainer,
  CaptchaErrorContainer,
} from "./ContactForm.styles";
import { useForm, Controller } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { messagesRef } from "../../firebase/";
import { addDoc, serverTimestamp } from "firebase/firestore";
import Spinner from "../spinner/Spinner";
import useSendForm from "../../hooks/useSendForm";

const ContactForm = () => {
  const [submissionStatusMsg, setSubmissionStatusMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { sendForm } = useSendForm();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const recaptchaRef = React.createRef();

  const onSubmit = (data) => {
    // Perform captcha validation
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setSubmissionStatusMsg({ color: "red", text: "reCAPTCHA is required" });
      return;
    }

    setIsLoading(true);

    // Replace undefined values in the data object with empty strings
    const replaceUndefinedWithEmptyString = (obj) => {
      const newObj = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          newObj[key] = obj[key] === undefined ? "" : obj[key];
        }
      }
      return newObj;
    };
    const cleanedData = replaceUndefinedWithEmptyString(data);

    // Handle form submission here

    // Send to Email

    sendForm(cleanedData);

    // Send to Firebase

    const formData = {
      ...cleanedData,
      createdAt: serverTimestamp(),
    };

    addDoc(messagesRef, formData)
      .then((res) => {
        setSubmissionStatusMsg({
          color: "green",
          text: "Successfully Submitted. Thank You!",
        });
      })
      .catch((err) => {
        setSubmissionStatusMsg({
          color: "red",
          text: "Ooops, Please Try Again!",
        });
        console.log(err);
      });

    // Reset the form and ReCAPTCHA after submission
    reset({
      name: "",
      company: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
    recaptchaRef.current.reset();

    setIsLoading(false);
  };

  const clearCaptchaErrorMessage = () => {
    setSubmissionStatusMsg(null);
  };

  useEffect(() => {
    if (!submissionStatusMsg) return;

    const timeoutId = setTimeout(clearCaptchaErrorMessage, 3500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [submissionStatusMsg]);

  const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

  return (
    <MainContainer>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <FieldRow>
          <Label>
            Name: <span>*</span>
          </Label>
          <Controller
            name="name"
            control={control}
            rules={{ required: "Name is required" }}
            render={({ field }) => <Input {...field} />}
          />
          {errors && errors.name && (
            <ErrorMessage className="red">{errors.name.message}</ErrorMessage>
          )}
        </FieldRow>
        <FieldRow>
          <Label>Company:</Label>
          <Controller
            name="company"
            control={control}
            render={({ field }) => <Input {...field} />}
          />
        </FieldRow>
        <FieldRow>
          <Label>
            Email: <span>*</span>
          </Label>
          <Controller
            name="email"
            control={control}
            rules={{
              required: "Email is required",
              pattern: {
                value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                message: "Invalid email format",
              },
            }}
            render={({ field }) => <Input {...field} />}
          />
          {errors && errors.email && (
            <ErrorMessage className="red">{errors.email.message}</ErrorMessage>
          )}
        </FieldRow>
        <FieldRow>
          <Label>Phone:</Label>
          <Controller
            name="phone"
            control={control}
            rules={{
              pattern: {
                value: /^[0-9]{10,15}$/,
                message:
                  "Invalid phone format. Please enter a valid phone number.",
              },
            }}
            render={({ field }) => <Input {...field} />}
          />
          {errors && errors.phone && (
            <ErrorMessage className="red">{errors.phone.message}</ErrorMessage>
          )}
        </FieldRow>
        <FieldRow>
          <Label>
            Subject: <span>*</span>
          </Label>
          <Controller
            name="subject"
            control={control}
            rules={{ required: "Subject is required" }}
            render={({ field }) => (
              <Dropdown {...field}>
                <Option value="">Select an option</Option>
                <Option value="General">General</Option>
                <Option value="Services">Services</Option>
                <Option value="Support">Support</Option>
                <Option value="Feedback">Feedback</Option>
              </Dropdown>
            )}
          />
          {errors && errors.subject && (
            <ErrorMessage className="red">
              {errors.subject.message}
            </ErrorMessage>
          )}
        </FieldRow>
        <FieldRow className="message">
          <Label>
            Message: <span>*</span>
          </Label>
          <Controller
            name="message"
            control={control}
            rules={{ required: "Message is required" }}
            render={({ field }) => <TextArea {...field} />}
          />
          {errors && errors.message && (
            <ErrorMessage className="red">
              {errors.message.message}
            </ErrorMessage>
          )}
        </FieldRow>

        {/* Google reCAPTCHA */}
        {reCaptchaSiteKey && (
          <CaptchaContainer>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={reCaptchaSiteKey}
              theme="dark"
              className="captchaContainer"
            />
          </CaptchaContainer>
        )}
        <CaptchaErrorContainer>
          {submissionStatusMsg && (
            <ErrorMessage className={submissionStatusMsg.color}>
              {submissionStatusMsg.text}
            </ErrorMessage>
          )}
        </CaptchaErrorContainer>
        <SubmitButton
          type="submit"
          className={isLoading && "disabled"}
          disabled={isLoading}
        >
          {isLoading ? <Spinner /> : "Submit"}
        </SubmitButton>
      </FormWrapper>
    </MainContainer>
  );
};

export default ContactForm;
