import styled from "styled-components";

export const MainContainer = styled.footer`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FooterContent = styled.p`
  color: #212529;
  text-align: center;
  margin: 20px auto 7px;
  line-height: 1.75;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`;
