import React from "react";
import { FooterContent, MainContainer } from "./Footer.styles";

const Footer = () => {
  return (
    <MainContainer>
      <FooterContent>
        Copyright {new Date().getFullYear()} © Ashkan Kardan
      </FooterContent>
    </MainContainer>
  );
};

export default Footer;
