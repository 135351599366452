import React from "react";
import {
  ProjectBox,
  ProjectContainer,
  ProjectContent,
  ProjectDescription,
  ProjectImg,
  ProjectImgContainer,
  ProjectLink,
  ProjectLogo,
  ProjectTech,
  ProjectTitle,
} from "./ProjectItem.styles";

const ProjectItem = ({ item }) => {
  return (
    <ProjectContainer>
      <ProjectBox>
        <ProjectContent>
          <ProjectImgContainer>
            <ProjectImg src={item.image} alt={item.imgalt} />
          </ProjectImgContainer>
          <ProjectLogo>{item.logo}</ProjectLogo>
          <ProjectTitle>{item.title}</ProjectTitle>
          <ProjectDescription>{item.description}</ProjectDescription>
          <ProjectTech>{item.tech}</ProjectTech>
          {item.links.map((l) => {
            return (
              <ProjectLink key={item.logo+l.name} target="_blank" href={l.url}>
                {l.name}
              </ProjectLink>
            );
          })}
        </ProjectContent>
      </ProjectBox>
    </ProjectContainer>
  );
};

export default ProjectItem;
