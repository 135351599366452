import React, { useState, useEffect, useRef } from "react";
import {
  LeftCol,
  MainContainer,
  RightColDesktop,
  RightColMobile,
  Logo,
  MainContent,
  MobileNavIconContainer,
  MobileNavIconIcon,
  MobileNavIconText,
  MobileNavIconIconContainer,
  DrawerContainer,
  DrawerContent,
  NavItemsContainer,
} from "./MainNav.styles.js";
import { useMobileNavContext } from "../../context/mobile-nav-context.js";
import { Link } from "react-scroll";
import LogoImage from "../../assets/img/ak-logo.png";

const MainMenu = () => {
  const [isSticky, setIsSticky] = useState(false);

  const { mobileNavMode, setMobileNavMode } = useMobileNavContext();

  const navRef = useRef();

  const handleCloseDrawer = () => {
    setMobileNavMode(false);
  };

  useEffect(() => {
    // Get the offset position of the navbar
    const sticky = navRef.current.offsetTop;

    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    const myFunction = () => {
      if (window.pageYOffset >= sticky) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    // Listen for scroll event and call myFunction
    window.addEventListener("scroll", myFunction);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener("scroll", myFunction);
    };
  }, []);

  return (
    <>
      <MainContainer ref={navRef} className={isSticky && "sticky"}>
        <MainContent className="maincontent">
          <Link
            to="/"
            spy={true}
            smooth={true}
            offset={0}
            duration={500}
            activeClass="active"
            className="NavLogoLink"
          >
            <LeftCol>
              <Logo src={LogoImage} alt="Ashkan Kardan Logo" />
            </LeftCol>
          </Link>

          <NavItemsContainer>
            <RightColDesktop>
              <Link
                to="about"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                activeClass="active"
              >
                About
              </Link>
              <Link
                to="skills"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                activeClass="active"
              >
                Skills
              </Link>
              <Link
                to="projects"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                activeClass="active"
              >
                Projects
              </Link>
              <Link
                to="articles"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                activeClass="active"
              >
                Articles
              </Link>
              <Link
                to="contact"
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
                activeClass="active"
              >
                Contact
              </Link>
            </RightColDesktop>
          </NavItemsContainer>

          <RightColMobile>
            <MobileNavIconContainer
              onClick={() => setMobileNavMode(!mobileNavMode)}
            >
              <MobileNavIconText>Menu</MobileNavIconText>

              <MobileNavIconIconContainer>
                <MobileNavIconIcon />
                <MobileNavIconIcon />
                <MobileNavIconIcon />
              </MobileNavIconIconContainer>
            </MobileNavIconContainer>
          </RightColMobile>
        </MainContent>
      </MainContainer>

      <DrawerContainer className={mobileNavMode ? "open" : "close"}>
        <DrawerContent>
          <NavItemsContainer className="MobileNavItemsContainer">
            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={20}
              duration={500}
              activeClass="active"
              onClick={handleCloseDrawer}
            >
              About
            </Link>
            <Link
              to="skills"
              spy={true}
              smooth={true}
              offset={20}
              duration={500}
              activeClass="active"
              onClick={handleCloseDrawer}
            >
              Skills
            </Link>
            <Link
              to="projects"
              spy={true}
              smooth={true}
              offset={20}
              duration={500}
              activeClass="active"
              onClick={handleCloseDrawer}
            >
              Projects
            </Link>
            <Link
              to="articles"
              spy={true}
              smooth={true}
              offset={20}
              duration={500}
              activeClass="active"
              onClick={handleCloseDrawer}
            >
              Articles
            </Link>
            <Link
              to="contact"
              spy={true}
              smooth={true}
              offset={20}
              duration={500}
              activeClass="active"
              onClick={handleCloseDrawer}
            >
              Contact
            </Link>
          </NavItemsContainer>
        </DrawerContent>
      </DrawerContainer>
    </>
  );
};

export default MainMenu;
