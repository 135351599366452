import EMProjectImage from "../assets/img/projects/event-master.png";
import TPProjectImage from "../assets/img/projects/the-picks.png";
import MSProjectImage from "../assets/img/projects/msets.png";
import NBProjectImage from "../assets/img/projects/nutsinbox.png";
import CHProjectImage from "../assets/img/projects/contenthub.png";
import ZPProjectImage from "../assets/img/projects/zilp.png";
import SAProjectImage from "../assets/img/projects/sentiment-analysis.png";

import { BsLinkedin } from "react-icons/bs";
import { BsFillEnvelopeAtFill } from "react-icons/bs";
import { FaPhoneFlip, FaSass } from "react-icons/fa6";

import {
  FaPython,
  FaReact,
  FaNode,
  FaHtml5,
  FaCss3Alt,
  FaNpm,
} from "react-icons/fa6";
import { IoLogoJavascript, IoLogoFigma } from "react-icons/io5";
import {
  SiJquery,
  SiExpress,
  SiWebpack,
  SiNextdotjs,
  SiFirebase,
  SiVercel,
  SiFlask,
  SiPandas,
  SiScikitlearn,
  SiTensorflow,
  SiKeras,
  SiPytorch,
  SiStyledcomponents,
  SiVisualstudiocode,
  SiSlack,
} from "react-icons/si";
import { BiLogoPostgresql } from "react-icons/bi";
import { BsBootstrapFill, BsGit, BsGithub } from "react-icons/bs";
import { DiAws, DiJira } from "react-icons/di";

import The3SPostImage from "../assets/img/posts/3s.png";
import BooleanPostImage from "../assets/img/posts/boolean.png";
import DryPostImage from "../assets/img/posts/dry-scalability.png";
import OpinionMiningPostImage from "../assets/img/posts/opinion-mining.png";
import ReactVsNextPostImage from "../assets/img/posts/react-vs-next.png";

export const ProjectsData = [
  {
    image: SAProjectImage,
    imgalt: "Sentiment Analysis app banner",
    logo: "SA",
    title: "Sentiment Analysis",
    description:
      "Machine Learning model to predict the sentiment (positive or negative) of a given list of reviews using NLP (Natural Language Processing) techniques.",
    tech: "Python | NLTK | XGBoost",
    links: [
      {
        url: "https://github.com/ashkankardan/Reviews-Sentiment-Analysis-and-Classification",
        name: "GitHub",
      },
    ],
  },

  {
    image: CHProjectImage,
    imgalt: "ContentHub app banner",
    logo: "CH",
    title: "ContentHub",
    description:
      "Dashboard app with clients, admins, teams, content, order and subscription management, support chat and ticketing, and data visualization.",
    tech: "React.js | Node.js | Firebase",
    links: [
      {
        url: "https://www.contenthub.io",
        name: "Live",
      },
    ],
  },

  {
    image: ZPProjectImage,
    imgalt: "Zilp app banner",
    logo: "ZP",
    title: "Zilp",
    description:
      "Social network and ride-sharing app with live tracking, private and public multimedia sharing, vehicle sale and rental, subscription and in-app transactions, and multi-level access dashboard.",
    tech: "Next.js | Node.js | Firebase | Vercel",
    links: [
      {
        url: "https://zilp-chat-app.vercel.app",
        name: "Demo",
      },
    ],
  },

  {
    image: NBProjectImage,
    imgalt: "NutsInBux app banner",
    logo: "NB",
    title: "NutsInBox",
    description:
      "E-commerce app with integrated payment processing for one-time and subscription-based transactions and a multi-level access dashboard for order and shipping management.",
    tech: "React.js | Node.js | Firebase",
    links: [
      {
        url: "https://nutsinbox.com",
        name: "Live",
      },
    ],
  },

  {
    image: MSProjectImage,
    imgalt: "Module Sets app banner",
    logo: "MS",
    title: "M|Sets",
    description:
      "A full-stack application for musicians to generate sound, modify waveforms, add effects, and save their session values to the database.",
    tech: "React.js | Node.js | Express | PostgreSQL",
    links: [
      {
        url: "https://github.com/ashkankardan/m-sets",
        name: "GitHub",
      },
    ],
  },

  {
    image: TPProjectImage,
    imgalt: "The Picks app banner",
    logo: "TP",
    title: "THE PICKS",
    description:
      "An interactive full-stack shopping cart application simulating a signature guitar picks eCommerce site.",
    tech: "React.js | Node.js | Express | PostgreSQL",
    links: [
      {
        url: "https://github.com/ashkankardan/wicked-sales-js",
        name: "GitHub",
      },
    ],
  },

  {
    image: EMProjectImage,
    imgalt: "Event Master app banner",
    logo: "EM",
    title: "EVENT MASTER",
    description:
      "A web application to display all current events based on the user’s location or by search.",
    tech: "JavaScript (ES5) | jQuery | APIs",
    links: [
      {
        url: "https://github.com/ashkankardan/event-master",
        name: "GitHub",
      },
    ],
  },
];

export const SkillsData = {
  top: [
    { name: "Python", logo: <FaPython /> },
    { name: "JavaScript", logo: <IoLogoJavascript /> },
    { name: "React.JS", logo: <FaReact /> },
    { name: "Node.JS", logo: <FaNode /> },
    { name: "Next.JS", logo: <SiNextdotjs /> },
    { name: "HTML5", logo: <FaHtml5 /> },
    { name: "CSS3", logo: <FaCss3Alt /> },
    { name: "Sass", logo: <FaSass /> },
    { name: "jQuery", logo: <SiJquery /> },
    { name: "PostgreSQL", logo: <BiLogoPostgresql /> },
  ],
  bottom: [
    { name: "AWS", logo: <DiAws /> },
    { name: "Firebase", logo: <SiFirebase /> },
    { name: "Vercel", logo: <SiVercel /> },
    { name: "Flask", logo: <SiFlask /> },
    { name: "Express", logo: <SiExpress /> },
    { name: "Pandas", logo: <SiPandas /> },
    { name: "SciKit-Learn", logo: <SiScikitlearn /> },
    { name: "TensorFlow", logo: <SiTensorflow /> },
    { name: "Keras", logo: <SiKeras /> },
    { name: "PyTorch", logo: <SiPytorch /> },
    { name: "Git", logo: <BsGit /> },
    { name: "GitHub", logo: <BsGithub /> },
    { name: "JIRA", logo: <DiJira /> },
    { name: "Styled Components", logo: <SiStyledcomponents /> },
    { name: "Bootstrap", logo: <BsBootstrapFill /> },
    { name: "npm", logo: <FaNpm /> },
    { name: "Webpack", logo: <SiWebpack /> },
    { name: "Figma", logo: <IoLogoFigma /> },
    { name: "VS Code", logo: <SiVisualstudiocode /> },
    { name: "Slack", logo: <SiSlack /> },
  ],
};

export const PostsData = [
  {
    title: "React vs. Next",
    image: ReactVsNextPostImage,
    imgalt: "React vs. Next post image",
    url: "https://www.linkedin.com/pulse/reactjs-vs-nextjs-comprehensive-comparison-ashkan-kardan",
  },
  {
    title: "Opinion Mining (NLP)",
    image: OpinionMiningPostImage,
    imgalt: "Opinion Mining post image",
    url: "https://www.linkedin.com/pulse/building-sentiment-analysis-model-nltk-xgboost-ashkan-kardan",
  },
  {
    title: "The 3S",
    image: The3SPostImage,
    imgalt: "The 3S post image",
    url: "https://www.linkedin.com/pulse/3s-split-slice-splice-ashkan-kardan/",
  },
  {
    title: "Simplify Your Boolean",
    image: BooleanPostImage,
    imgalt: "Simplify Your Boolean post image",
    url: "https://www.linkedin.com/pulse/simplify-your-boolean-logic-ashkan-kardan/",
  },
  {
    title: "DRY & Scalability",
    image: DryPostImage,
    imgalt: "DRY & Scalability post image",
    url: "https://www.linkedin.com/pulse/dry-dont-repeat-yourself-scalability-importance-ashkan-kardan/",
  },
];

export const ContactMethodsData = {
  icons: [
    {
      content: <BsGithub />,
      url: "https://github.com/ashkankardan",
      display: "github.com/ashkankardan",
      isText: false,
    },
    {
      content: <BsLinkedin />,
      url: "https://www.linkedin.com/in/ashkankardan",
      display: "linkedin.com/in/ashkankardan",
      isText: false,
    },
    {
      content: <BsFillEnvelopeAtFill />,
      url: "mailto:ashkan@ashkankardan.com",
      display: "ashkan@ashkankardan.com",
      isText: false,
    },
    {
      content: <FaPhoneFlip />,
      url: "tel:+19499423732",
      display: "+1 (949) 942-3732",
      isText: false,
    },
  ],
  texts: [],
};
