import emailjs from "@emailjs/browser";

const useSendForm = (formData) => {
  const sendForm = async (formData) => {
    const formDataObj = {
      from_name: formData.name,
      reply_to: formData.email,
      subject: formData.subject,
      company: formData.company,
      phone: formData.phone,
      message: formData.message,
    };

    emailjs
      .send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        formDataObj,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          return true;
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return { sendForm };
};

export default useSendForm;
