import React, {createContext, useContext, useState} from 'react';

const MobileNavStateContext = createContext(undefined)
const MobileNavDispatchContext = createContext(undefined)

const MobileNavContextProvider = ({children}) => {
  const [mobileNavMode, setMobileNavMode] = useState(false)
  return (
    <MobileNavStateContext.Provider value={mobileNavMode}>
      <MobileNavDispatchContext.Provider value={setMobileNavMode}>
        {children}
      </MobileNavDispatchContext.Provider>
    </MobileNavStateContext.Provider>
  );
};

export default MobileNavContextProvider;

export const useMobileNavContext = () => {
  const mobileNavMode = useContext(MobileNavStateContext)
  const setMobileNavMode = useContext(MobileNavDispatchContext)

  if (mobileNavMode === undefined || setMobileNavMode === undefined) throw new Error('useMobileNavContext must be used within the MobileNavContextProvider')

  return {mobileNavMode, setMobileNavMode}
}
