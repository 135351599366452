import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const LeftCol = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const RightCol = styled.div`
  width: 100%;
  text-align: justify;
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  color: white;
`;

export const AboutText = styled.p`
  line-height: 1.75;
`;

export const ProfileImg = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 50%;
  box-shadow: 0.5px 0.5px 7px 3px #424242;
  transition: all 1s ease;
  margin-bottom: 25px;

  &:hover {
    box-shadow: 0.5px 0.5px 6px 5px #5c5c5c;
  }

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }
`;

export const ConnectBtnContainer = styled.div`
  &.mobile {
    display: block;
    margin-top: 20px;
  }

  &.desktop {
    display: none;
  }

  @media (min-width: 768px) {
    &.mobile {
      display: none;
    }

    &.desktop {
      display: block;

    }
  }
`;
