import { collection, getDocs } from "firebase/firestore";
import { db } from "./config";

const usersRef = collection(db, "users");
const messagesRef = collection(db, "messages");

const snapshotToDoc = (doc) => {
  const docData = doc.data();
  const docObject = {
    ...docData,
    id: doc.id,
  };

  return docObject;
};

export { getDocs, usersRef, messagesRef, snapshotToDoc };
