import React, { useState, useEffect } from "react";
import { MainContainer } from "./Projects.styles";
import ProjectItem from "./ProjectItem";
import { ProjectsData } from "../../data/data";

const Projects = () => {
  const [projectList, setProjectList] = useState(null);

  useEffect(() => {
    if (!ProjectsData) return;
    setProjectList(ProjectsData);
  }, []);

  return (
    <MainContainer>
      {projectList &&
        projectList.map((item) => {
          return <ProjectItem key={item.title} item={item} />;
        })}
    </MainContainer>
  );
};

export default Projects;
