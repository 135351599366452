import styled from "styled-components";

export const MainContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FormWrapper = styled.form`
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 7px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const FieldRow = styled.div`
  height: 85px;
  width: 100%;

  &.message {
    height: 205px;
  }
`;

export const Label = styled.label`
  color: white;
  display: inline-block;
  margin-bottom: 7px;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  span {
    color: #830000;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1.5px solid #fec810;
  background-color: #212529;
  color: white;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1.5px solid #fec810;
  background-color: #212529;
  color: white;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  resize: vertical;
  height: 150px;
  margin-bottom: 0;
  box-sizing: border-box;
  font-size: 14px;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`;

export const Dropdown = styled.select`
  width: 100%;
  height: 40px;
  padding: 10px;
  padding-top: 8px;
  border: 1.5px solid #fec810;
  background-color: #212529;
  color: white;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  font-size: 14px;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`;

export const Option = styled.option`
  color: black;
`;

export const SubmitButton = styled.button`
  width: 285px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  color: #fed136;
  background-color: black;
  border: 1.5px solid #fed136;
  font-size: 20px;
  box-sizing: border-box;
  transition: all 0.15s;
  cursor: pointer;
  margin: auto;

  &:hover {
    color: gray;
    border-color: gray;
  }

  &:active {
    color: white;
    border-color: white;
  }

  &.disabled {
    color: gray;
    border-color: gray;
    cursor: not-allowed;
  }
`;

export const ErrorMessage = styled.p`
  font-size: 14px;
  margin-top: 0;
  text-align: end;
  opacity: 1
  transition: opacity 0.15s ease;

  &.red {
    color: #830000;
  }

  &.green {
    color: #00ff00;
  }
`;

export const CaptchaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`;

export const CaptchaErrorContainer = styled.div`
  height: 24px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
