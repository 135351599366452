import React, { useState, useEffect } from "react";
import Buttons from "../buttons/Buttons";
import {
  BtnItemLinkContainer,
  IconBtnsContainer,
  MethodDisplayContainer,
  MethodDisplayContent,
} from "./ContactMethod.styles";
import { ContactMethodsData } from "../../data/data";

const ContactMethod = ({ withDisplay }) => {
  const [methodList, setMethodList] = useState(null);
  const [methodDisplay, setMethodDisplay] = useState("@");

  const handleMouseEnter = (itemDisplay) => setMethodDisplay(itemDisplay);
  const handleMouseLeave = () => setMethodDisplay("@");

  useEffect(() => {
    if (!ContactMethodsData) return;
    setMethodList(ContactMethodsData);
  }, []);

  return (
    <>
      {withDisplay && (
        <MethodDisplayContainer>
          <MethodDisplayContent>{methodDisplay}</MethodDisplayContent>
        </MethodDisplayContainer>
      )}
      <IconBtnsContainer>
        {methodList &&
          methodList.icons.map((item) => {
            return (
              <BtnItemLinkContainer
                onMouseEnter={() => handleMouseEnter(item.display)}
                onMouseLeave={handleMouseLeave}
                key={item.display}
                target="_blank"
                href={item.url}
              >
                <Buttons isText={item.isText}>{item.content}</Buttons>
              </BtnItemLinkContainer>
            );
          })}
      </IconBtnsContainer>
      {methodList &&
        methodList.texts.map((item) => {
          return (
            <BtnItemLinkContainer
              onMouseEnter={() => handleMouseEnter(item.display)}
              onMouseLeave={handleMouseLeave}
              key={item.display}
              target="_blank"
              href={item.url}
            >
              <Buttons isText={item.isText}>{item.content}</Buttons>
            </BtnItemLinkContainer>
          );
        })}
    </>
  );
};

export default ContactMethod;
