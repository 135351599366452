import styled, { keyframes } from "styled-components";

export const IconBtnsContainer = styled.div`
  width: 285px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
`;

export const BtnItemLinkContainer = styled.a`
  text-decoration: none;
`;

export const MethodDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DisplayContentAnimation = keyframes`
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
`;

export const MethodDisplayContent = styled.p`
  height: 50px;
  margin-bottom: 30px;
  padding-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  position: relative;
  background-color: black;
  color: white;
  font-size: 1rem;

  &::before {
    content: "";
    position: absolute;
    top: -1px;
    background: linear-gradient(45deg, #830000, black, #830000, black);
    background-size: 400%;
    width: 100%;
    height: 54px;
    z-index: -1;
    animation: ${DisplayContentAnimation} 20s linear infinite;
  }
`;
