import "./App.css";
import Scrollbar from "./components/scrollbar/Scrollbar";
import MobileNavContextProvider from "./context/mobile-nav-context";
import Layout from "./layout/Layout";

function App() {
  return (
    <>
      <MobileNavContextProvider>
        <Layout />
        <Scrollbar />
      </MobileNavContextProvider>
    </>
  );
}

export default App;
