import styled from "styled-components";

export const SkillItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: grab;
`;

export const SkillItemTitle = styled.div`
  color: #212529;
  font-size: 22px;
  font-weight: bold;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  transition: all 1s ease;
  text-align: center;
`;

export const SkillItem = styled.div`
  width: 124px;
  max-width: 124px;
  max-height: 124px;
  min-width: 124px;
  min-height: 124px;
  height: 124px;
  border-radius: 50%;
  background-color: #fec810;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  transition: all 1s ease;
  font-size: 60px;

  &:hover {
    transform: rotate(360deg);
  }

  &:hover + div {
    color: #830000;
  }
`;
