import React from "react";
import {
  BannerBtn,
  BannerBtnContainer,
  Heading,
  MainContainer,
  MainContent,
  SubHeading,
} from "./Hero.styles";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { Link } from "react-scroll";

const Hero = () => {
  const [text] = useTypewriter({
    words: [
      "Software Engineer",
      "Web Application Developer",
      "Machine Learning Engineer",
    ],
    loop: false,
  });

  return (
    <MainContainer>
      <MainContent>
        <Heading>Hi, I'm Ashkan!</Heading>

        <SubHeading>
          {text}
          <span>
            <Cursor cursorColor="red" />
          </span>
        </SubHeading>

        <Link
          to="about"
          spy={true}
          smooth={true}
          offset={20}
          duration={500}
          activeClass="active"
        >
          <BannerBtn href="#about">About Me</BannerBtn>
        </Link>
      </MainContent>
    </MainContainer>
  );
};

export default Hero;
