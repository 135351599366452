import styled from "styled-components";

export const MainContainer = styled.div``;

export const HeroContainer = styled.div`
  width: 100vw;
  transform: translateX(-8px);
`;

export const ContentContainer = styled.div`
  width: 90%;
  max-width: 1140px;
  margin: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const SectionContainer = styled.section`
  width: 100%;
  padding-top: 100px;
  margin: 0 auto 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionTitle = styled.h2`
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  color: #212529;
  text-transform: uppercase;
`;

export const SectionSubTitle = styled.h3`
  font-size: 15px;
  font-weight: 400;
  font-style: italic;
  margin-top: 0;
  margin-bottom: 70px;
  color: #6c757d;
  text-align: center;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  &.contactSubTitle {
    margin-bottom: 16px;
  }
`;

export const SectionDivider = styled.div`
  height: 1px;
  width: 50%;
  background-color: #830000;
  margin: auto;
`;
