import styled from "styled-components";
import backgroundImage from "../../assets/img/ak-banner.png";

export const MainContainer = styled.div`
  width: 100%;
  padding-top: 10.5rem;
  padding-bottom: 6rem;
  text-align: left;
  color: #fff;
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: cover;

  @media (min-width: 768px) {
    padding-top: 17rem;
    padding-bottom: 12.5rem;
  }
`;

export const MainContent = styled.div`
  width: 90%;
  max-width: 1140px;
  margin: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const Heading = styled.div`
  font-size: 2.6rem;
  font-weight: 700;
  line-height: 3.25rem;
  -webkit-text-stroke: 3px black;
  color: #fec810;
  margin-bottom: 15px;

  @media (min-width: 430px) {
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 18px;
  }

  @media (min-width: 768px) {
    font-size: 4.5rem;
    line-height: 4rem;
    margin-bottom: 30px;
  }
`;

export const SubHeading = styled.div`
  font-size: 1.5rem;
  font-style: italic;
  line-height: 1.5rem;
  margin-bottom: 25px;
  margin-bottom: 3rem;
  -webkit-text-stroke: 1.5px black;
  color: white;
  font-size: 1.3rem;
  height: 42px;
  font-weight: bold;
  margin-bottom: 13px;
  @media (min-width: 430px) {
    font-size: 1.6rem;
    font-style: italic;
    line-height: 2.25rem;
    margin-bottom: 22px;
  }

  @media (min-width: 768px) {
    font-size: 2.25rem;
    font-style: italic;
    line-height: 2.25rem;
    margin-bottom: 2rem;
  }
`;

export const BannerBtn = styled.a`
  width: 175px;
  height: 52px;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px 35px;
  color: gray;
  background-color: black;
  border: 1.5px solid gray;
  font-size: 18px;
  box-sizing: border-box;
  transition: all 0.15s;

  &:hover {
    color: #fed136;
    border-color: #fed136;
  }

  &:active {
    color: white;
    border-color: white;
  }

  @media (min-width: 768px) {
    width: 185px;
    height: 55px;
    padding: 14px 40px;
  }
`;
