import React from "react";
import {
  AboutText,
  ConnectBtnContainer,
  LeftCol,
  MainContainer,
  RightCol,
} from "./About.styles";
import { ProfileImg } from "./About.styles";
import ProfileImage from "../../assets/img/ashkankardan.JPG";
import Buttons from "../buttons/Buttons";
import { Link } from "react-scroll";

const About = () => {
  return (
    <MainContainer>
      <LeftCol>
        <ProfileImg src={ProfileImage} />
        <Link
          to="contact"
          spy={true}
          smooth={true}
          offset={20}
          duration={500}
          activeClass="active"
        >
          <ConnectBtnContainer className="desktop">
            <Buttons isText={true}>Let's Connect</Buttons>
          </ConnectBtnContainer>
        </Link>
      </LeftCol>
      <RightCol>
        <AboutText>
          From putting old random computer parts together to build my system,
          and from manipulating HTML/CSS templates for clients’ e-commerce sites
          to becoming a Software and Machine Learning Engineer, my passion has
          always been to enhance the synergy between humans and machines by
          improving my understanding of the technology.
        </AboutText>
        <AboutText>
          I love chatting about new tech, working on projects, collaborating,
          and I'm open to any opportunity that'd challenge my creativity.
        </AboutText>
        <AboutText>Feel free to reach out and connect.</AboutText>
      </RightCol>
      <Link
        to="contact"
        spy={true}
        smooth={true}
        offset={20}
        duration={500}
        activeClass="active"
      >
        <ConnectBtnContainer className="mobile">
          <Buttons isText={true}>Let's Connect</Buttons>
        </ConnectBtnContainer>
      </Link>
    </MainContainer>
  );
};

export default About;
