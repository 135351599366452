import React from "react";
import MainNav from "../components/nav/MainNav";
import Home from "../pages/Home";
import Hero from "../components/hero/Hero";
import {
  ContentContainer,
  HeroContainer,
  MainContainer,
  SectionContainer,
  SectionDivider,
  SectionSubTitle,
  SectionTitle,
} from "./Layout.styles";
import About from "../components/about/About";
import Skills from "../components/skills/Skills";
import Posts from "../components/posts/Posts";
import Projects from "../components/projects/Projects";
import Contact from "../components/contact/Contact";
import Footer from "../components/footer/Footer";

const Layout = () => {
  return (
    <MainContainer>
      <MainNav />
      <HeroContainer id="/">
        <Hero />
      </HeroContainer>

      <SectionDivider />

      <ContentContainer>
        <SectionContainer id="about">
          <SectionTitle>About</SectionTitle>
          <SectionSubTitle>
            Lifelong Learner! Love solving complex problems, by turning coffee
            into code!
          </SectionSubTitle>
          <About />
        </SectionContainer>

        <SectionDivider />

        <SectionContainer id="skills">
          <SectionTitle>skills</SectionTitle>
          <SectionSubTitle>My current tech stack.</SectionSubTitle>
          <Skills />
        </SectionContainer>

        <SectionDivider />

        <SectionContainer id="projects">
          <SectionTitle>projects</SectionTitle>
          <SectionSubTitle>Application Developed</SectionSubTitle>
          <Projects />
        </SectionContainer>

        <SectionDivider />

        <SectionContainer id="articles">
          <SectionTitle>articles</SectionTitle>
          <SectionSubTitle>Articles Published.</SectionSubTitle>
          <Posts />
        </SectionContainer>

        <SectionDivider />

        <SectionContainer id="contact">
          <SectionTitle>contact</SectionTitle>
          <SectionSubTitle className="contactSubTitle">Feel free to reach out and connect.</SectionSubTitle>
          <Contact />
        </SectionContainer>
      </ContentContainer>
      <SectionDivider />
      <Footer />
    </MainContainer>
  );
};

export default Layout;
